





















import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'
import LoadEnableSubjectsApi from '@/mixins/utils/LoadEnableSubjectsApi'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'

@Component({
  components: {
    SidebarSwitcher,
    ColoredBox,
    ButtonBase,
  },
})
export default class SelectSubject extends Mixins(StyleSubjectBackground, LoadEnableSubjectsApi, ClassModeMethods) {
  public async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.resetCurriculumSUnitsGrade()
    await this.loadEnableSubjects()
    Vue.prototype.$loading.complete()
  }

  private subjectDatas: { text: string; code: string }[] = []

  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private classMode = this.$route.params.classMode

  @Watch('$route')
  async onChangeRoute(): Promise<void> {
    this.classMode = this.$route.params.classMode
  }

  /**
   * 教科選択
   */
  private async selectSubject(subjectCode: string): Promise<any> {
    // 項目選択画面へ遷移
    this.$router.push(`/student/v2/curriculum-s-units/${this.classMode}/${subjectCode}`)
  }

  /**
   * サービス設定で有効な教科を取得し、教科プルダウンに反映
   */
  private async loadEnableSubjects() {
    const enableSubjects = await this.loadGdlsSubjectsApi(this.branchId, this.userId)

    this.subjectDatas = enableSubjects.map((subject) => {
      return { text: subject.name, code: subject.code }
    })
  }

  /**
   * 項目リストの学年選択情報をリセットする
   */
  private async resetCurriculumSUnitsGrade() {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    delete cookie['curriculumSUnitsGrade']
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  private get classModeName() {
    return this.classModeText(this.classMode)
  }
}
